import React from 'react';

import { createUseStyles } from 'react-jss';

import Styles from './ContactForm.styles';

const useStyles = createUseStyles(Styles);

const ContactForm = props => {
  const classes = useStyles(props);
  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <h3>Social Media</h3>
        <p>
          Per rimanere sempre in contatto iscriviti al{' '}
          <a
            href="https://www.facebook.com/groups/195891088828285"
            target="_blank"
          >
            Gruppo Facebook
          </a>{' '}
          o segui la{' '}
          <a href="https://www.instagram.com/liveflow.it/" target="_blank">
            Pagina Instagram
          </a>
        </p>
        {/* <form
          action="/contattaci-success"
          method="POST"
          name="contacForm"
          className={classes.form}
          data-netlify="true"
          netlify-honeypot="bot-field"
        >
          <input type="hidden" name="form-name" value="contacForm" />
          <p>
            <label htmlFor="email">
              Nome{' '}
              <input
                className={classes.input}
                id="name"
                type="text"
                name="name"
                placeholder="Il tuo nome"
              />
            </label>
          </p>
          <p>
            <label htmlFor="email">
              Email{' '}
              <input
                className={classes.input}
                type="email"
                id="email"
                name="email"
                placeholder="La tua email"
              />
            </label>
          </p>
          <p>
            <label htmlFor="message">
              Messaggio{' '}
              <textarea
                className={classes.input}
                name="message"
                id="message"
                cols="30"
                rows="10"
                placeholder="Inserisci qui il tuo messaggio"
              ></textarea>
            </label>
          </p>
          <p className={classes.buttonContainer}>
            <button type="submit" className={classes.button}>
              Invia
            </button>
          </p>
        </form> */}
      </div>
    </div>
  );
};

ContactForm.propTypes = {};

export default ContactForm;
