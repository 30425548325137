import Colors from '@utils/colors.js';
import Utils from '@utils';
import Breakpoints from '@utils/breakpoints';
import Dimensions from '@utils/dimensions';

const Styles = {
  container: {
    maxWidth: Dimensions.contentMidWidth,
    margin: '0 auto',
  },
  content: {
    // maxWidth: Dimensions.contentBlogWidth,
    margin: '40px 0',
    fontSize: '18px',
  },
  input: {
    padding: '18px 10px',
    borderRadius: '4px',
    border: `1px solid ${Colors.base}`,
    marginRight: '20px',
    width: '100%',
    display: 'block',
    marginTop: '5px',
    resize: 'none',
    [`@media (max-width: ${Breakpoints.m})`]: {
      marginRight: '0',
      marginBottom: '10px',
    },
    [`@media (max-width: ${Breakpoints.s})`]: {
      width: '100%',
    },
  },
  buttonContainer: {
    textAlign: 'right',
  },
  button: {
    color: Colors.white + ' !important',
    textDecoration: 'none !important',
    backgroundColor: Colors.accent,
    borderRadius: '4px',
    padding: '20px 25px',
    fontSize: '14px',
    textTransform: 'uppercase',
    display: 'inline-block',
    fontWeight: 'bold',
    transition: Utils.transition('background', '.2s'),
    letterSpacing: 1,
    cursor: 'pointer',
    border: 'none',
    '&:hover': {
      backgroundColor: Colors.accentDark,
    },
    [`@media (max-width: ${Breakpoints.s})`]: {
      width: '100%',
    },
    [`@media (max-width: ${Breakpoints.xxs})`]: {
      padding: '15px 20px',
    },
  },
};

export default Styles;
