import React from 'react';

import Layout from '@layout/Layout';
import PageContainer from '@layout/PageContainer';
import Seo from '@components/Seo/Seo';
import Newsletter from '@components/Newsletter/Newsletter';

import PageHeader from '@components/PageHeader/PageHeader';
import ContactForm from '@components/ContactForm/ContactForm';

const Contattaci = ({ location }) => {
  const title = 'Contattami';
  const subtitle =
    'Mandami un messaggio <a href="mailto:t.helboe@gmail.com">qui</a> per qualsiasi informazione, domanda o suggerimento.';
  const description =
    'Compila subito il modulo per entrare in contatto con LiveFlow.';
  return (
    <Layout>
      <Seo title={title} description={description} url={location.pathname} />
      <PageContainer>
        <PageHeader title={title} subtitle={subtitle} nomargin />
        <ContactForm />
        <Newsletter />
      </PageContainer>
    </Layout>
  );
};

export default Contattaci;
